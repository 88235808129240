<template lang="pug">
div(v-if='facets.length > 0' class='flex flex-wrap gap-x-2 gap-y-1')
  template(v-for='facet in facetsToDisplay' :key='`${facet.facet}-${facet.value}`')
    button(
      class='flex items-center gap-x-2 text-sm bg-white py-1 px-2 border border-solid border-gray-light rounded'
      type='button'
      @click='removeFacetAndUpdateSearch(facet.facet, facet.value)'
    )
      slot(:name='facet.facet' :value='facet.value' :label='facet.label')
        | {{ facet.label }}

      CloseIcon(class='w-2 h-2 fill-current text-gray')

  button(
    v-if='facetsOverMaxDisplay > 0'
    class='flex items-center text-sm bg-white py-1 px-2 border border-solid border-gray-light rounded'
    type='button'
    @click='showAllRefinements = !showAllRefinements'
  )
    template(v-if='showAllRefinements') Show Less
    template(v-else) + {{ facetsOverMaxDisplay }}

  button(
    class='flex items-center text-sm bg-white py-1 px-2 border border-solid border-gray-light rounded'
    type='button'
    @click='clearAllFacets'
  ) Clear All
</template>

<script setup lang="ts">
import CloseIcon from '@/assets/x.svg?component'

const { maxDisplayCount = 4 } = defineProps<{
  maxDisplayCount?: number
}>()

const { instance, updateSearch } = useConstructorInject()

const { sameDayShippingText } = useSameDayShippingDisplay()

const showAllRefinements = ref(false)

const facets = computed(() => {
  return instance.state.value.activeFacets.map((activeFacet) => {
    const activeFacetObj = {
      ...activeFacet,
    }

    if (activeFacetObj.facet === 'sameDayShipping') {
      activeFacetObj.label = `${sameDayShippingText} Only`
    }

    return activeFacetObj
  })
})

const facetsToDisplay = computed(() => {
  return facets.value.slice(0, showAllRefinements.value ? undefined : maxDisplayCount)
})

const facetsOverMaxDisplay = computed(() => {
  return facets.value.length - maxDisplayCount
})

function removeFacetAndUpdateSearch(facet: string, value: string) {
  instance.removeFacetValue(facet, value)

  updateSearch()
}

function clearAllFacets() {
  instance.clearFacets()

  updateSearch()
}
</script>
