<template lang="pug">
div
  //- This is the anchor that will be used to scroll back to when the route changes
  div(:id='id')

  slot
</template>

<script setup lang="ts">
import { provideInjectKey } from '../helpers/keys'

const { instance, updateUrl = true } = defineProps<{
  instance: ConstructorInstance
  updateUrl?: boolean
}>()

provide(provideInjectKey, {
  instance,
  updateSearch,
})

const { $scrollTo } = useNuxtApp()
const route = useRoute()

const id = useId()

watch(
  () => route.query,
  () => {
    $scrollTo(`#${id}`, 300, {
      force: false,
      offset: -10,
    })
  }
)

async function updateSearch() {
  if (updateUrl) {
    const { mod } = route.query

    const query = {
      ...instance.getSearchParamsForQuery(),
    }

    if (mod) query.mod = mod

    await navigateTo({ query })
  } else {
    await instance.search()
  }
}
</script>
