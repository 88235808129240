<template lang="pug">
div(v-if='$sitewideConfig.config.sameDayShippingEnabled && facetData')
  slot(
    :is-active='isActive'
    :is-disabled='isDisabled'
    :toggle-facet='toggleFacet'
    :toggle-colors='toggleColors'
    :same-day-shipping-text='sameDayShippingText'
  )
    div(:class='{ "opacity-25": isDisabled }')
      ConstructorFacet(:is-light='isLight')
        template(#header)
          button(class='w-full px-4 py-2 text-left' :class='classes' @click='toggleFacet')
            span(class='flex justify-between items-center gap-x-2')
              span(class='uppercase break-words')
                | {{ sameDayShippingText }} Only

              span(
                class='flex-none ml-2 my-2 h-4 w-8 flex items-center rounded-full border-2 border-transparent'
                :class='toggleColors'
              )
                span(
                  class='h-3 w-3 bg-white rounded-full transform shadow'
                  :class='[!isActive ? "translate-x-0" : "translate-x-4"]'
                )

        template(#default)
          div
            p(v-if='!isDisabled && !isActive' class='px-4 py-2 text-sm') Only display items that ship the quickest
</template>

<script setup lang="ts">
const { instance, updateSearch } = useConstructorInject()

defineProps<{
  isLight?: boolean
}>()

const { $sitewideConfig } = useNuxtApp()
const notifications = useNotifications()
const fitmentDisplayStore = useFitmentDisplayStore()
const { sameDayShippingText, sameDayShippingBorderTopColor, sameDayShippingBackgroundColor } =
  useSameDayShippingDisplay()

const facetData = computed(() => {
  return instance.state.value.facets.find((f) => f.name === 'sameDayShipping')
})

const values = computed(() => instance.getFacetValues(facetData.value?.name || ''))

const isActive = computed(() => {
  return values.value.includes('True')
})

const isDisabled = computed(() => {
  return !isActive.value && !facetData.value?.options.some(({ value }) => value === 'True')
})

const toggleColors = computed(() => {
  if (isActive.value) {
    if ($sitewideConfig.config.sameDayShippingMode === 'RAPIDSHIP') return 'bg-success-dark'

    return 'bg-info-dark'
  }
  return 'bg-gray-dark'
})

function toggleFacet() {
  if (isDisabled.value) {
    notifications.addInfo(
      `Oops, no ${sameDayShippingText} items available for that selection`,
      `Don't worry! Just choose a different brand, category, or price range to see ${sameDayShippingText} items!`
    )
    return
  }

  // If sameDayShipping is active, remove it
  if (isActive.value) instance.clearFacets('sameDayShipping')
  // Since we adding the facet if we get to this else if,
  // If the user doesn't have full fitment, then we need to open the fitment modal instead of applying the facet.
  else if (!fitmentDisplayStore.hasFullFitment) {
    fitmentDisplayStore.showFitmentModal({ isSameDayShippingMode: true })
    return
  }
  // Just add the facet because we are in a valid state to do so.
  else instance.addFacetValue('sameDayShipping', 'True')

  updateSearch()
}

const classes = computed(() => {
  return {
    [sameDayShippingBorderTopColor]: !isDisabled.value,
    'border-t-2': !isDisabled.value,
    [sameDayShippingBackgroundColor]: isActive.value,
    'text-white': isActive.value,
  }
})
</script>
