<template lang="pug">
div(v-if='totalPages > 1')
  ul(class='flex items-center justify-center space-x-2 md:justify-end')
    template(v-for='(item, index) in pageItems' :key='`${item.label || item.type}-${index}`')
      li
        template(v-if='item.type === "ellipsis"')
          div(class='flex justify-center items-end')
            | &hellip;

        template(v-else-if='item.type === "page"')
          NuxtLink(:to='{ query: { ...$route.query, page: item.page } }' custom)
            template(#default='{ href }')
              a(
                :href='href'
                class='flex justify-center items-center text p-2 rounded md:w-10 md:h-10 md:hover:border md:border-gray-dark md:hover:no-underline'
                :class='{ "or-pagination-active font-bold md:bg-primary": item.isActive }'
                @click.prevent='goToPage(item.page)'
              )
                | {{ item.label }}

        template(v-else)
          NuxtLink(:to='{ query: { ...$route.query, page: item.page } }' custom)
            template(#default='{ href }')
              a(
                :href='href'
                class='flex justify-center items-center gap-x-2 text px-3 w-10 h-10 bg-white border rounded md:w-auto'
                :class='{ "flex-row-reverse": item.type === "prev" }'
                @click.prevent='goToPage(item.page)'
              )
                span(class='hidden lg:block') {{ item.label }}
                img(
                  src='@/assets/arrow.svg'
                  class='w-3 transform'
                  :class='[item.type === "prev" ? "rotate-90" : "-rotate-90"]'
                  height='12'
                  width='12'
                  :alt='item.label'
                )
</template>

<script setup lang="ts">
const { instance, updateSearch } = useConstructorInject()

const page = computed(() => {
  return instance.state.value.page
})

const totalPages = computed(() => {
  return instance.state.value.totalPages
})

const rangeStart = computed(() => {
  let startPage = 2

  if (totalPages.value > 7 && page.value > 4) {
    if (page.value > totalPages.value - 4) startPage = totalPages.value - 4
    else startPage = page.value - 1
  }

  return startPage
})

const rangeEnd = computed(() => {
  let endPage = totalPages.value - 1

  if (totalPages.value > 7) {
    if (page.value < 5) endPage = 5
    else if (page.value < totalPages.value - 3) endPage = page.value + 1
  }

  return endPage
})

const pageItems = computed(() => {
  const range: { page?: number; label?: string; type: 'page' | 'ellipsis' | 'next' | 'prev'; isActive?: boolean }[] = [
    {
      type: 'page',
      label: '1',
      isActive: page.value === 1,
    },
  ]

  if (rangeStart.value > 2) range.push({ type: 'ellipsis' })

  for (let i = rangeStart.value; i <= rangeEnd.value; i++)
    range.push({ type: 'page', page: i, label: i.toString(), isActive: page.value === i })

  if (totalPages.value - rangeEnd.value > 1) range.push({ type: 'ellipsis' })

  if (page.value > 1) {
    const prevPage = page.value - 1
    range.unshift({ type: 'prev', page: prevPage > 1 ? prevPage : undefined, label: 'Prev' })
  }

  range.push({
    type: 'page',
    page: totalPages.value,
    label: totalPages.value.toString(),
    isActive: page.value === totalPages.value,
  })

  if (page.value < totalPages.value) {
    range.push({ type: 'next', page: page.value + 1, label: 'Next' })
  }

  return range
})

function goToPage(pageNumber?: number) {
  instance.setPage(pageNumber)

  updateSearch()
}
</script>
