<template lang="pug">
div(class='rounded border border-solid overflow-hidden' :class='showHighlightedBorder ? "border-info" : "border-gray-lighter"')
  div(class='font-bold' :class='headerColors')
    slot(name='header' :toggle-collapsed='toggleCollapsed')
      button(class='w-full px-4 py-2 text-left' @click='toggleCollapsed')
        span(class='flex justify-between items-center gap-x-2')
          span(class='uppercase break-words')
            template(v-if='facetLabel')
              | {{ facetLabel }}

          span(class='flex-none')
            span(class='text-[20px]')
              template(v-if='isCollapsed') +
              template(v-else) -

        span(
          v-if='showSelectedRefinementsWhenCollapsed && isCollapsed && selectedFacetOptions'
          class='font-normal text-sm text-gray transform-none truncate'
        ) {{ selectedFacetOptions }}

  div(v-show='!isCollapsed' class='bg-white')
    slot(:is-collapsed='isCollapsed')
      div(class='px-4 py-2 text-sm space-y-4 lg:overflow-x-hidden lg:overflow-y-auto lg:max-h-[500px]')
        slot(name='options' :options='facetOptions')
          div(v-for='facetOption in facetOptions' :key='facetOption.value')
            SwitchGroup
              div(class='flex items-center gap-x-2.5')
                Switch(
                  v-slot='{ checked }'
                  as='template'
                  :model-value='facetOption.isSelected'
                  @update:model-value='toggleFacetValue(facetOption.value, facetOption.isSelected)'
                )
                  button(
                    class='relative inline-flex justify-center items-center h-5 w-5 border-2 border-solid rounded focus:outline-none focus:ring-2 focus:ring-offset-2'
                    :class='checked ? "bg-action border-action" : "border-gray-dark"'
                  )
                    img(v-if='checked' src='/images/checkmarkThick.svg' class='w-3 h-3' height='12' width='12')

                SwitchLabel(class='flex-1')
                  slot(name='optionLabel' :label='facetOption.label')
                    | {{ facetOption.label }}
</template>

<script setup lang="ts">
import type { Facet } from '@constructor-io/constructorio-client-javascript'
import { SwitchGroup, SwitchLabel, Switch, provideUseId } from '@headlessui/vue'

// We need to do this to so headlessUI doesn't cause hydration errors/
// See: https://github.com/tailwindlabs/headlessui/issues/2913#issuecomment-1924681165
provideUseId(() => useId())

const { label, facet, options, isLight, isCollapsedDefault, showHighlightedBorderWhenRefined } = defineProps<{
  label?: string
  facet?: Partial<Facet>
  options?: { label: string; value: string }[]
  isLight?: boolean
  isCollapsedDefault?: boolean
  showSelectedRefinementsWhenCollapsed?: boolean
  showHighlightedBorderWhenRefined?: boolean
}>()

const { instance, updateSearch } = useConstructorInject()

const isCollapsed = ref(isCollapsedDefault)

const headerColors = computed(() => {
  return ['text-dark', isLight ? 'bg-white' : 'bg-gray-lighter']
})

const facetLabel = computed(() => label ?? facet?.display_name)

// This is where we get the facet values in our current instance.
// If we just use the state response then we would be waiting for the network
// request to resolve before we could show a checkmark in the option's checkbox
const values = computed(() => instance.getFacetValues(facet?.name || ''))

const facetOptions = computed(() => {
  return (options ?? facet?.options ?? []).map((option) => ({
    label: option.label ?? option.display_name,
    value: option.value,
    isSelected: values.value.includes(option.value),
  }))
})

const selectedFacetOptions = computed(() => {
  return facetOptions.value
    .filter((option) => option.isSelected)
    .map((option) => option.value)
    .join(', ')
})

const showHighlightedBorder = computed(() => {
  return !!(showHighlightedBorderWhenRefined && selectedFacetOptions.value)
})

function toggleCollapsed() {
  isCollapsed.value = !isCollapsed.value
}

function toggleFacetValue(value: string, isSelected: boolean) {
  if (isSelected) instance.removeFacetValue(facet?.name || '', value)
  else instance.addFacetValue(facet?.name || '', value)

  updateSearch()
}
</script>
