import { provideInjectKey } from '../helpers/keys'

export default function () {
  const instance = inject(provideInjectKey)

  if (!instance) {
    throw new Error('Constructor instance not found')
  }

  return instance
}
