<template lang="pug">
div
  template(v-if='$device.value.isSmall')
    div(class='flex flex-col items-center sm:flex-row sm:justify-between')
      div(class='w-full flex justify-between items-center gap-2 sm:w-auto')
        ConstructorFacetSameDayShipping(v-if='$sitewideConfig.config.sameDayShippingEnabled' class='flex-initial')
          template(#default='{ isActive, isDisabled, toggleFacet, toggleColors, sameDayShippingText }')
            BaseButton(
              size='SMALL'
              color='GRAY'
              button-style='OUTLINE'
              :class='{ "opacity-25": isDisabled }'
              @click='toggleFacet'
            )
              | {{ sameDayShippingText }}

              div(
                class='flex-none ml-2 h-4 w-6 lg:w-8 flex items-center rounded-full border-2 border-transparent'
                :class='toggleColors'
              )
                span(
                  class='h-3 w-3 bg-white rounded-full transform shadow'
                  :class='[!isActive ? "translate-x-0" : "translate-x-2 lg:translate-x-4"]'
                )

        div(v-if='!$sitewideConfig.config.nonTransactionalEnabled' class='flex-auto')
          BaseButton(size='SMALL' color='GRAY' button-style='OUTLINE' class='w-full' @click='$overlay.open("sortMenu")')
            img(src='/images/sort.svg' alt='sort' class='h-2 w-2 mr-2' loading='lazy')
            | Sort

        div(class='flex-auto')
          BaseButton(
            size='SMALL'
            color='GRAY'
            button-style='OUTLINE'
            has-custom-padding
            class='w-full px-4 py-3 sm:px-6'
            @click='$overlay.open("facetMenu")'
          )
            span(class='w-[18px] mr-1 -ml-1 justify-end inline-flex')
              img(v-if='activeFacetCount === 0' src='/images/filter.svg' alt='filter' class='h-2 w-2' loading='lazy')
              span(
                v-else
                class='w-[18px] h-[18px] leading-none rounded-full items-center justify-center inline-flex bg-gray-light'
              ) {{ activeFacetCount }}
            span Filter

      div(class='py-4 sm:order-first sm:p-0')
        ConstructorStats

    Overlay(unique-key='facetMenu' enable-dark-mode is-persistent)
      template(#header)
        div(class='flex items-center')
          p Filter
          div(class='font-normal text-sm pl-1')
            | (#[ConstructorStats(class='inline')])

      template(#default)
        CatalogConstructorCategory(class='mb-3' :category-data='categoryData' is-light is-collapsed-default)
        ConstructorFacets(
          is-light
          is-collapsed-default
          show-highlighted-border-when-refined
          show-selected-refinements-when-collapsed
        )

      template(#footer)
        div(class='flex')
          BaseButton(color='GRAY' class='w-full mr-3' @click='clearAllFacets') Clear All
          BaseButton(class='w-full ml-3' @click='$overlay.close') Done

    Overlay(v-if='!$sitewideConfig.config.nonTransactionalEnabled' unique-key='sortMenu' title='Sort' is-persistent)
      ConstructorSortBy
        template(#sort='{ label, isActive }')
          span(
            class='flex items-center before:w-5 before:h-5 before:rounded-[50%] before:border-2 before:border-solid before:inline-block before:mr-[15px] before:relative bg-white p-4 mb-4 cursor-pointer border-solid rounded text-lg text-left'
            :class='isActive ? "before:border-[7px] before:border-solid before:border-action border-2 border-action" : "border border-gray-light"'
          ) {{ label }}

      template(#footer)
        BaseButton(class='w-full' @click='$overlay.close') Close

  template(v-else)
    ConstructorResults
      template(#results)
        div(class='flex justify-between items-center mb-6')
          ConstructorStats
          ConstructorSortBy(v-if='!$sitewideConfig.config.nonTransactionalEnabled' label='Sort By:')
</template>

<script setup lang="ts">
defineProps<{
  categoryData: any
}>()

const { instance, updateSearch } = useConstructorInject()

const activeFacetCount = computed(() => {
  return instance.state.value.activeFacets.length
})

function clearAllFacets() {
  instance.clearFacets()

  updateSearch()
}
</script>
