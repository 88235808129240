<template lang="pug">
div(class='whitespace-nowrap font-bold text-dark')
  span(class='hidden lg:inline') {{ resultStart }} - {{ resultEnd }} of&nbsp;
  | {{ instance.state.value.resultTotal }} {{ plural(instance.state.value.resultTotal, 'results', 'result') }}
</template>

<script setup lang="ts">
const { instance } = useConstructorInject()

const resultStart = computed(() => {
  const pageZeroBased = instance.state.value.page - 1

  return pageZeroBased * instance.state.value.resultsPerPage + 1
})

const resultEnd = computed(() => {
  return resultStart.value + instance.state.value.results.length - 1
})
</script>
