<template lang="pug">
div(v-if='instance.state.value.sortOptions.length > 1')
  template(v-if='$slots.sort')
    template(v-for='(sort, index) in instance.state.value.sortOptions' :key='`${sort.sort_by}-${sort.sort_order}`')
      button(class='block w-full' @click='activeSort = index')
        slot(name='sort' :label='sort.display_name' :is-active='index === activeSort')

  template(v-else)
    div(class='flex items-center text-sm font-bold')
      span(v-if='label') {{ label }}&nbsp;

      div(class='select is-small')
        select(v-model='activeSort')
          template(
            v-for='(sort, index) in instance.state.value.sortOptions'
            :key='`${sort.sort_by}-${sort.sort_order}`'
          )
            option(:value='index') {{ sort.display_name }}
</template>

<script setup lang="ts">
const { label = '' } = defineProps<{
  label?: string
}>()

const { $uiEvents } = useNuxtApp()

const { instance, updateSearch } = useConstructorInject()

const activeSort = computed({
  get() {
    const index = instance.state.value.sortOptions.findIndex((sort) => sort.status === 'selected')
    // Make sure we have a valid match
    return index > -1 ? index : 0
  },
  set(value) {
    const selectedSort = instance.state.value.sortOptions[value]

    instance.setSortBy(selectedSort.sort_by, selectedSort.sort_order)

    updateSearch()

    $uiEvents.$emit('constructorProductListSorted', {
      displayName: selectedSort.display_name,
      sortBy: selectedSort.sort_by,
      sortOrder: selectedSort.sort_order,
    })
  },
})
</script>
